@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@500&family=Roboto:wght@500&display=swap');
*
{
  margin: 0;
  padding: 0;
  

}
body{
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* dashboard css @@@@@@@@@@@@@ */
.dashboard-course-card
{
  height: 10rem;
  width: 10rem;
 display: flex;
 justify-content: center;
 align-items: center;
 background: red;
 background: #0ba22b;
 
}
.course-dashboard
{
  height: 100vh;
  width: 20%;
  box-shadow: 5px 10px 10px #999;
 
}

/* Dashboard css Ends  */
.course-container
{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  
}
@media (max-width: 768px) {
  .course-container {
    width: 100%;
    /* margin-left:10%; */
  }
  .course-card
  {
    width: 100%;
    
 
  }
  .course-title h2
  {
    font-size: 1.3rem;
  }
  .course-text p
  {
   color:rgb(10, 77, 82);
   font-size:.9rem;
   font-weight: 700;
  }
  .course-text button{
    height:2.2rem ;
    width:6rem;
  
    border-radius:30px;
    border:none;
    font-weight:400;
    color:white;
  
    
    background:#1e1a4a;
  }
}

.course-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  
  width: 80%;
height: 11rem;
  /* gap: 60px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  /* new css */

  display: flex;
  /* flex: wrap; */
  align-items: center;
  text-align: center;
  /* background: #d0e3ff; */
}
.course-title
{
  width: 40%;
  height: 100%;
  background: #1e1a4a;
 border-top-left-radius: 10px;
 border-bottom-left-radius: 10px;
 display: flex;
 justify-content: center;
 align-items: center;
 color: white;
  
 
}
.course-text
{
  display: flex;
  flex-direction: column; 
  flex: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width: 70%;
  /* position: relative; */
  
}
.course-text h3{
  font-weight: 500;
  font-family: roboto;

  font-size: 1.3rem;
}
.course-text button{
  height:2.3rem ;
  width:8rem;
/* margin-top: 3rem; */
  border-radius:30px;
  border:none;
  font-weight:600;
  color:white;

  
  background:#1e1a4a;
}

.course-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}




/* lognin page css  */
.login-container {
  display: flex;
  height: 100vh;
}

.left-panel {
  width: 50%;
  background-color: #0077b6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-panel img {
  max-width: 100%;
  max-height: 100%;
}

.right-panel {
  width: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.input-wrapper {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}

.input-wrapper input {
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.input-wrapper label {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  transition: all 0.2s ease-out;
  color: #999;
  pointer-events: none;
}

.input-wrapper input:focus + label,
.input-wrapper input:valid + label.active {
  top: -0.5rem;
  left: 0.5rem;
  font-size: 0.8rem;
  color: #0077b6;
  background-color: #fff;
  padding: 0 0.25rem;
}

.login_btn {
  padding: 0.5rem 1rem;
  background-color: #0077b6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* login page css ends */

/* wwelcome-banner code starts */
.welcome-banner
{
  height: 13rem;
  border-radius: 10px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  background: #1e1a4a;
  color: white;
  display: flex;
  justify-content:space-around;
  text-align: center;
  align-items: center;

  flex-wrap: wrap;
}

span{
  color: #ffffff;
  font-weight: 900;
}

/* welcome-banner ends */

/* course page css */.course-page-container {
  max-width: 100%;
  overflow: hidden;
}

.course-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-bottom: 2px solid #ccc;
  height: 4rem;
  padding: 20px;
}

.course-header img {
  height: 50px;
}

.logout-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-button {
  background-color: none;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logout-button img {
  height: 30px;
  width: 30px;
}

.return {
  margin-left: 5rem;
}

.return .home-logo {
  height: 30px;
  cursor: pointer;
}

.course-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
}

.course-modules {
  flex: 1 1 100%; /* Full width by default */
  padding: 10px;
}

.course-modules h4 {
  padding-left: 20px;
}

.course-modules ul {
  list-style-type: none;
  padding: 20px;
}

.module-item {
  cursor: pointer;
  margin-bottom: 10px;
  border-bottom: 2px solid #ccc;
  padding: 10px;
}

.module-item.selected {
  color: blue;
}

.course-video {
  flex: 1 1 100%; /* Full width by default */
  padding: 10px;
  border: 2px solid #ccc;
}

.video-container {
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  padding: 10px;
  
  height: 50vh; /* Adjusted height for video container */
}

.video-container video {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .course-content {
    flex-direction: row; /* Default row layout on larger screens */
  }

  .course-modules {
    flex: 1 1 30%; /* 30% width for modules */
  }

  .course-video {
    flex: 1 1 70%; /* 70% width for video */
  }
}
